@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,700;1,400&family=Nunito:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');
body {
    background-color: #fff !important;
    /*background-color: '#F5F5F5' !important;*/
    font-family: 'Saira', sans-serif !important;
}

html,
body {
    height: 100%;
    position: relative;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-left {
    display: flex;
    align-items: center;
}

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
}

.effect8 {
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
        0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
        0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.zoom {
    transition: transform 0.2s !important;
    -webkit-transform: translate3d(0, 0, 0);
}

.zoom:hover {
    transform: scale(1.02);
}

.highlight {
    transition: ease-in 0.2s !important;
}

.highlight:hover {
    color: #3e9cd9 !important;
}

.box {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: none !important;
    /* border-top: 1px solid rgba(0, 0, 0, 0.12) !important; */
}

.box h1 {
    font-weight: 700;
    font-family: 'Saira', Roboto, sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 16px;
    font-size: 24px;
}

.box .content {
    padding-left: 16px;
}

.effect8:before,
.effect8:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
}

.effect8:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
}

.muted {
    color: grey;
}

.locked-tile {
    opacity: 0.5;
    pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}

.main-container {
    min-height: 100vh;
    /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 200px;
    /* height of your footer */
}

@media (max-width: 600px) {
    .main-container {
        padding-bottom: 250px;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-content {
    /* text-align: right; */
}

@media (max-width: 600px) {
    .footer-content /*div:nth-child(1)*/ {
        text-align: center;
    }
}

footer .copyright {
    text-align: center;
}

.popover {
    padding: 1rem !important;
}

.float-right {
    float: right;
}

.link {
    color: #3e9cd9 !important;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.ihq-blue {
    color: #3e9cd9 !important;
}

.ihq-red {
    color: red !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-y-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.m-y-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.gray-label {
    margin-left: 1rem;
    color: grey;
    font-size: 0.9rem;
}

.hoverable {
    transition: ease-in 0.2s !important;
}

.hoverable:hover {
    color: #3e9cd9 !important;
    cursor: pointer;
}

.no-decoration {
    text-decoration: none;
}

.hidden {
    display: none;
}

.invisible {
    opacity: 0;
}
